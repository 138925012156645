import { ApiService } from "./api.service";
import { ClientModel, CreateClientModel } from "../models/clients/client.model";
import { IResponse } from "../models/response";
import { UsersModel } from "../models/users/users.model";
import { useQuery, useMutation, MutationFunction } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ErrorTypeModel } from "../models/error/error.model";

// Get List of all clients
export const useFetchClients = (
  page = 1,
  itemsPerPage = 10,
  filters?: Record<string, unknown>,
  sort?: Record<string, unknown>
) => {
  const queryKey = [
    "clients",
    page,
    itemsPerPage,
    JSON.stringify(filters),
    sort,
  ];

  const { refetch, ...queryResult } = useQuery<
    IResponse<ClientModel[]>,
    AxiosError
  >(queryKey, async () => {
    const params: Record<string, unknown> = {
      page: page,
      per_page: itemsPerPage,
      sortField: sort?.field,
      sortDirection: sort?.sort,
      ...filters,
    };

    const clientsData = await ApiService.get("/clients", { params });
    return clientsData.data;
  });

  return { ...queryResult, refetch };
};

// Get client details
export const useFetchClient = (id?: string) => {
  const queryKey = ["clients", id];

  const { refetch, ...queryResult } = useQuery<
    IResponse<ClientModel>,
    AxiosError
  >(queryKey, () => ApiService.get(`/clients/${id}`), {
    staleTime: 60 * 1000,
    enabled: Boolean(id),
  });
  return { ...queryResult, refetch };
};

export const useFetchActiveClients = () => {
  const queryKey = ["active-clients"];

  return useQuery<IResponse<UsersModel>, AxiosError>(queryKey, () =>
    ApiService.get("/get-active-clients")
  );
};

// Create new client
export const useCreateClient = () => {
  const createClient = useMutation<
    IResponse<CreateClientModel>,
    AxiosError<ErrorTypeModel>,
    FormData
  >(async (formData: FormData) => {
    // Make the API request using the formData
    const response = await ApiService.postFormData(`/clients`, formData);
    return response.data;
  });
  return createClient;
};

// Create new client
export const useUploadClientsExcel = () => {
  const createClients: MutationFunction<
    IResponse<CreateClientModel>,
    FormData
  > = async (formData) => {
    // Make the API request using the formData
    const response = await ApiService.postFormData(`/clients/import`, formData);
    return response.data;
  };
  return useMutation(createClients);
};

// Update client
export const useUpdateClient = (id: string) => {
  const updateClient = useMutation<
    IResponse<CreateClientModel>,
    AxiosError<ErrorTypeModel>,
    FormData
  >(async (formData: FormData) => {
    const response = await ApiService.postFormData(
      `/clients/${id}?_method=PATCH`,
      formData
    );
    return response;
  });
  return updateClient;
};
