import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { GridFilterModel } from "@mui/x-data-grid";
import { transformFilterItemToFilterObject } from "../utils/helper";

interface FiltersState {
	filters: {
		[key: string]: GridFilterModel | undefined;
	};
	setFilters: (key: string, filterModal?: GridFilterModel) => void;
	clearFilters: (key: string) => void;
	getFilterObject: (key: string) => { field: string; value: any } | undefined;
}

const useFiltersStore = create<FiltersState>()(
	persist(
		(set, get) => ({
			filters: {},
			setFilters: (key, filterModal) =>
				set(() => ({
					filters: { ...get().filters, [key]: filterModal },
				})),
			clearFilters: (key) =>
				set(() => {
					const filters = get().filters;
					delete filters[key];
					return {
						filters: filters,
					};
				}),
			getFilterObject: (key) => {
				const filters = get().filters[key];
				return filters && transformFilterItemToFilterObject(filters.items[0]);
			}
		}),
		{
			name: "filters",
			storage: createJSONStorage(() => sessionStorage),
		}
	)
);

export default useFiltersStore;
