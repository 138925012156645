import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import general from './de/general.json';
import components from './de/components.json';

export const defaultNS = 'general';
export const resources = {
    general,
    components
}

i18n.use(initReactI18next).init({
  lng: 'de',
  fallbackLng: "de",
  debug: false,
  resources: {
    de: resources,
  },
  defaultNS,
});

export default i18n;