import AddIcon from "@mui/icons-material/Add";
import Can from "../../casl/Can";
import DataTable from "../shared/table";
import Header from "../shared/header";
import LinkButton from "../shared/link-button";
import React, { useEffect, useState, FunctionComponent } from "react";
import useBreadcrumbStore from "../../stores/store-breadcrumbs";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useFetchProducts } from "../../api/product";
import { columns } from "./products.columns";
import {
  GridPaginationModel,
  GridSortItem,
} from "@mui/x-data-grid";
import useFiltersStore from "../../stores/store-filters";

interface ProductsListProps {}
const ProductsList: FunctionComponent<ProductsListProps> = () => {
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  // Filter
  const filterKey = 'products';
  const filter = useFiltersStore((state) => state.getFilterObject(filterKey));
  // Sort
  const [sort, setSort] = useState({});

  const {
    data: productsData,
    isLoading,
    isError,
  } = useFetchProducts(itemsPerPage, currentPage, filter, sort);

  const products = productsData?.data; // Extract data from the 'data' property
  const productsTotalItems = productsData?.pager?.total;

  // reset the breadcrumb
  const resetBreadcrumb = useBreadcrumbStore((state) => state.resetBreadcrumb);

  const handlePageChange = (newPage: GridPaginationModel) => {
    // Fetch new data from the server based on the new page
    setCurrentPage(newPage.page + 1);
    setItemsPerPage(newPage.pageSize);
  };

  useEffect(() => {
    resetBreadcrumb();
  }, [resetBreadcrumb]);

  const handleSortModelChange = (sortItem: GridSortItem) => {
    setSort(sortItem);
  };

  return (
    <>
      {!isError && (
        <Box
          sx={{
            marginTop: "60px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header title="Produkte" />
            <Can I="create" a="Product">
              <LinkButton
                label="Produkt erstellen"
                startIcon={<AddIcon />}
                to="/products/create"
              />
            </Can>
          </Box>
          <Can I="view" a="Products">
            <Box marginTop="40px">
              <DataTable
                filterKey={filterKey}
                parentPath="products"
                data={products ?? []}
                fieldsNames={columns}
                boxHeight={75}
                onPageChange={handlePageChange}
                currentPage={currentPage - 1}
                totalItems={productsTotalItems}
                itemsPerPage={itemsPerPage}
                ssrFilter={true}
                isLoading={isLoading}
                onSortChange={handleSortModelChange}
                ssrSort={true}
                showFilterPanel={true}
              />
            </Box>
          </Can>
          <Outlet />
        </Box>
      )}
    </>
  );
};

export default ProductsList;
