import AddIcon from "@mui/icons-material/Add";
import Can from "../../casl/Can";
import DataTable from "../shared/table";
import Header from "../shared/header";
import LinkButton from "../shared/link-button";
import React, { FunctionComponent, useState } from "react";
import { useFetchRoles } from "../../api/roles";
import { Box } from "@mui/material";
import { columns } from "./roles.columns";
import {
  GridFilterItem,
  GridPaginationModel,
  GridSortItem,
} from "@mui/x-data-grid";
import { transformFilterItemToFilterObject } from "../../utils/helper";

interface RolesListProps {}

/**
 * RolesList Component
 *
 * This React functional component displays a list of roles in a data table format.
 * It includes features for pagination, filtering, and sorting.
 * Additionally, authorized users can add new roles using a dedicated button.
 *
 * @remarks
 * The component leverages the `useFetchRoles` hook to retrieve role data from the API.
 * It utilizes the `DataTable` component for displaying roles and offers user interface elements to manage pagination,
 * filters, and sorting. Role-based access control (RBAC) is implemented to restrict functionalities based on user permissions.
 *
 *
 * @example
 * ```tsx
 * import RolesList from "./RolesList";
 *
 * <RolesList />;
 * ```
 *
 * @returns {JSX.Element} A JSX element rendering the roles list.
 */

const RolesList: FunctionComponent<RolesListProps> = () => {
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  // Filter
  const [filters, setFilters] = useState({});
  // Sort
  const [sort, setSort] = useState({});

  // Get Roles
  const {
    data: rolesData,
    isLoading,
    isError,
  } = useFetchRoles(currentPage, itemsPerPage, filters, sort);
  const roles = rolesData?.data; // Extract data from the 'data' property
  const rolesTotalItems = rolesData?.pager?.total;

  /**
   * Handles changes in pagination.
   *
   * @param {GridPaginationModel} newPage - The new page number and page size.
   */
  const handlePageChange = (newPage: GridPaginationModel) => {
    setCurrentPage(newPage.page + 1);
    setItemsPerPage(newPage.pageSize);
  };

  /**
   * Handles changes in the filter model.
   *
   * @param {GridFilterItem} filterItem - The filter item to apply.
   */
  const handleFilterModelChange = (filterItem: GridFilterItem) => {
    const filterObject = transformFilterItemToFilterObject(filterItem);
    setFilters(filterObject);
  };

  /**
   * Handles changes in the sort model.
   *
   * @param {GridSortItem} sortItem - The sort item to apply.
   */
  const handleSortModelChange = (sortItem: GridSortItem) => {
    setSort(sortItem);
  };

  return (
    <>
      {!isError && (
        <Box
          sx={{
            marginTop: "60px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header title="Rolle" />
            <Can I="create" a="Role">
              <LinkButton
                label="Rolle hinzufügen"
                startIcon={<AddIcon />}
                to="/roles/create"
              />
            </Can>
          </Box>
          <Can I="view" a="Roles">
            <Box marginTop="40px">
              <DataTable
                parentPath="roles"
                data={roles ?? []}
                fieldsNames={columns}
                boxHeight={75}
                currentPage={currentPage - 1}
                totalItems={rolesTotalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
                ssrFilter={true}
                isLoading={isLoading}
                onFilterChange={handleFilterModelChange}
                onSortChange={handleSortModelChange}
                ssrSort={true}
              />
            </Box>
          </Can>
        </Box>
      )}
    </>
  );
};
export default RolesList;
