import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import React, { useEffect, useState } from "react";
import { Box, Chip, ChipProps, useTheme } from "@mui/material";
import { Link as MuiLink, Button, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { setNotificationAsReadOrReceived } from "../../api/notification";
import { formatDate, showNotification } from "../../utils/helper";
import { tokens } from "../layout/theme";
import { translations } from "../../data/mockData";
import { useTranslation } from "react-i18next";
import "../../i18n";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridFilterItem,
  GridFilterModel,
  GridOverlay,
  GridPaginationModel,
  GridSortItem,
  GridSortModel,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useGridApiRef } from "@mui/x-data-grid";
import { TaskType } from "../../models/tasks/task-status.enum";
import useFiltersStore from "../../stores/store-filters";

type ExtendedGridColDef = GridColDef & { hide?: boolean };

type Props = {
  data: any;
  fieldsNames: ExtendedGridColDef[];
  parentPath?: string;
  boxHeight?: number;
  pxHeight?: string;
  audits?: boolean;
  notifications?: boolean;
  currentPage?: number;
  totalItems?: number;
  itemsPerPage?: number;
  noDataMessage?: string;
  ssrFilter?: boolean;
  ssrSort?: boolean;
  isLoading?: boolean;
  showFilterPanel?: boolean;
  notes?: boolean;
  onPageChange?: (newPage: any) => void;
  onFilterChange?: (filterItems: GridFilterItem | any) => void;
  onSortChange?: (sortModel: GridSortItem | any) => void;
  refetch?: () => void;
  customLink?: string;
  filterKey?: string;
};

const DataTable = ({
  parentPath,
  data,
  fieldsNames,
  boxHeight,
  pxHeight,
  audits,
  notifications,
  notes,
  currentPage,
  totalItems,
  itemsPerPage,
  ssrFilter = false,
  ssrSort = false,
  isLoading = false,
  showFilterPanel = false,
  onPageChange,
  onFilterChange,
  onSortChange,
  refetch,
  customLink,
  filterKey,
}: Props) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const visibleColumns = fieldsNames.filter((column) => !column.hide);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: currentPage ?? 0,
    pageSize: itemsPerPage ?? 0,
  });
  // eslint-disable-next-line
  const [currentItemPerPage, setCurrentItemPerPage] = useState(itemsPerPage);
  const pageSizeOptions = Array.from(
    new Set([currentItemPerPage ? currentItemPerPage : 10, 15, 25, 50])
  );
  const [rowCountState, setRowCountState] = useState(totalItems);

  // Filters state
  const { filters, setFilters, clearFilters } = useFiltersStore((state) => state);
  const filterModal = filters[filterKey ?? ''];
  const [ currentFilterModal, setCurrentFilterModal ] = useState(filterModal);

  const apiRef = useGridApiRef();

  useEffect(() => {
    setCurrentFilterModal(filterModal);
  }, [filterModal]);

  useEffect(() => {
    if (showFilterPanel) {
      apiRef.current.showFilterPanel();
    }
  }, [apiRef, showFilterPanel]);

  useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      totalItems !== undefined ? totalItems : prevRowCountState
    );
  }, [totalItems, setRowCountState]);

  const handleRowClick: GridEventListener<"rowClick"> = async (params) => {
    if (
      (!params.row?.parent_task_id &&
      params.row?.task_type === TaskType.wiederkehrend)
      || params.row.repeated_type === "recurring_task"
    )
      // if the row presents a task of type recurring, it should not be clickable
      return;
    if (notifications) {
      const rowData = params.row; // Access the row data directly
      await showNotification(rowData.type, rowData.body, rowData.title, false);
      setNotificationAsReadOrReceived(rowData.id, true);
      if (refetch) refetch();
    } else if (notes) {
      const rowData = params.row; // Access the row data directly
      await showNotification("normal", rowData.text, rowData.name, false);
    } else if (audits) {
      const rowData = params.row;
      let html = "";
      const changes = [] as any;
      for (const [key1] of Object.entries(rowData.old_values_all)) {
        for (const [key2, value2] of Object.entries(
          rowData.old_values_all[key1]
        )) {
          changes[key2] = {
            from: value2,
            to: null,
          };
        }
      }
      for (const [key1] of Object.entries(rowData.new_values_all)) {
        for (const [key2, value2] of Object.entries(
          rowData.new_values_all[key1]
        )) {
          if (changes[key2]) {
            changes[key2].to = value2;
          } else {
            changes[key2] = {
              from: null,
              to: value2,
            };
          }
        }
      }
      for (const key in changes) {
        const keyValue = t(`components:fields.${key}` as any);
        const fromValue = i18n.exists(
          `components:fields.${key}.values.${changes[key].from}`
        )
          ? t(`components:fields.${key}.values.${changes[key].from}`)
          : changes[key].from;
        const toValue = i18n.exists(
          `components:fields.${key}.values.${changes[key].to}`
        )
          ? t(`components:fields.${key}.values.${changes[key].to}`)
          : changes[key].to;
        if (key !== "image") {
          html += `<b>${keyValue}:</b> (${
            fromValue ?? "-"
          }) <b>TO</b> (${toValue})</br></br>`;
        } else {
          html += `<b>${keyValue}.</b> </br></br>`;
        }
      }
      html = `<div style='text-align:left'>${html}</div>`;
      await showNotification("normal", html, rowData.user_name, false, true);
    }
    // else if (parentPath) {
    //   // we need to pass state to show the name in the breadcrumbs
    //   navigate(`/${parentPath}/${params.id}`, { state: params.row });
    // } else if (customLink) {
    //   if (customLink.includes("{client_id}")) {
    //     customLink = customLink.replace(
    //       "{client_id}",
    //       params.row?.client_id || params.row?.building?.client?.id
    //     );
    //   }
    //   if (customLink.includes("{building_id}")) {
    //     customLink = customLink.replace(
    //       "{building_id}",
    //       params.row?.building_id ||
    //         params.row?.plant?.building_id ||
    //         params.row?.building?.id
    //     );
    //   }
    //   if (customLink.includes("{plant_id}")) {
    //     customLink = customLink.replace(
    //       "{plant_id}",
    //       params.row?.plant_id || params.row?.plant?.id
    //     );
    //   }
    //   if (customLink.includes("{disturbances_id}")) {
    //     customLink = customLink.replace("{disturbances_id}", params.row?.id);
    //   }
    //   if (customLink.includes("{task_id}")) {
    //     customLink = customLink.replace("{task_id}", params.row?.id);
    //   }
    //   navigate(`${customLink}`, { state: params.row });
    // }
  };

  const getLinkWithIds = (link: string, data: any) => {
    if (link.includes("{client_id}")) {
      link = link.replace(
        "{client_id}",
        data?.client_id || data?.building?.client?.id
      );
    }
    if (link.includes("{building_id}")) {
      link = link.replace(
        "{building_id}",
        data?.building_id ||
          data?.plant?.building_id ||
          data?.building?.id
      );
    }
    if (link.includes("{plant_id}")) {
      link = link.replace(
        "{plant_id}",
        data?.plant_id || data?.plant?.id
      );
    }
    if (link.includes("{disturbances_id}")) {
      link = link.replace("{disturbances_id}", data?.id);
    }
    if (link.includes("{task_id}")) {
      link = link.replace("{task_id}", data?.id);
    }

    return link;
  }

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (onSortChange) {
      onSortChange(sortModel[0]);
    }
  };

  const CustomNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>Keine Datensätze</div>
      </GridOverlay>
    );
  };

  const handleFilterModelChange = (filterModel: GridFilterModel) => {
    if (filterKey) {
      setFilters(filterKey, filterModel);
    } else if(onFilterChange) {
      onFilterChange(filterModel?.items?.[0]);
      setCurrentFilterModal(filterModel);
    }
  };

  const CustomToolbar = () => {
    return (
      <Box display="flex" justifyContent="end" gap="10px">
        {currentFilterModal && (
          <Button
            variant="text"
            startIcon={<FilterAltOffIcon />}
            sx={{ padding: "1em", color: colors.blueAccent[200] }}
            onClick={() => {
              if (filterKey) {
                clearFilters(filterKey);
              } else if(onFilterChange) {
                onFilterChange({});
                setCurrentFilterModal(undefined);
              }
            }}
          >
            Filter löschen
          </Button>
        )}
        <GridToolbarFilterButton
          sx={{ padding: "1em", color: colors.blueAccent[200] }}
        />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        // height: "100%",
        width: "100%",
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-main": {
          background: colors.primary[400],
        },
        "& .super-app-theme--header": {
          backgroundColor: colors.blueAccent[700],
          fontSize: "17px",
        },
        "& .super-app-theme--cell": {
          fontSize: "16px",
          "&.urgent-cell": {
            backgroundColor: "red",
          },
          "&.normal-cell": {
            backgroundColor: "green",
          },
          "&.alert-cell": {
            backgroundColor: "yellow",
          },
          whiteSpace: "nowrap !important",
          overflow: "hidden !important",
          textOverflow: "ellipsis !important",
        },
        "& .MuiDataGrid-footerContainer": {
          border: "none",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
          backgroundColor: colors.blueAccent[700],
        },
        overflowY: "hidden", // Hide vertical scroll
      }}
    >
      {/* {data?.length ? ( */}
      <DataGrid
        apiRef={apiRef}
        rows={data}
        columns={[
          ...visibleColumns.map((column) => {
            if (
              column.field === "type" ||
              column.field === "taskStatus" ||
              column.field === "subTaskStatus" ||
              column.field === "status"
            ) {
              return {
                ...column,
                renderCell: (params: any) => {
                  let chipColor = "success"; // Default color
                  if (
                    params.value === "urgent" ||
                    params.value === "Verzögert" ||
                    params.value === "Falsch"
                  ) {
                    chipColor = "error";
                  } else if (params.value === "alert") {
                    chipColor = "warning";
                  }
                  return (
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Chip
                              color={chipColor as ChipProps["color"]}
                              label={params.value}
                              size="medium"
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  );
                },
              };
            }
            if (column.field === "end_date") {
              return {
                ...column,
                renderCell: (params: any) => {
                  return formatDate(params.value);
                },
              };
            }
            if (parentPath || customLink) {
              return {
                ...column,
                renderCell: (params: any) => {
                  const link = parentPath
                  ? `/${parentPath}/${params.id}`
                  : customLink
                  ? getLinkWithIds(customLink, params.row)
                  : "";

                  return (
                    <MuiLink
                      component={RouterLink}
                      to={link}
                      underline="none"
                      color="inherit"
                      width="100%"
                      height="100%"
                      display="flex"
                      alignItems="center"
                    >
                      {params.value}
                    </MuiLink>
                  )
                },
              };
            }
            return column;
          }),
        ]}
        onRowClick={handleRowClick}
        rowCount={rowCountState ?? 0}
        pageSizeOptions={pageSizeOptions}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={(newModel) => {
          // Update your local paginationModel state here if needed
          setPaginationModel(newModel);

          // Call your onPageChange function to handle page changes
          if (onPageChange) {
            onPageChange(newModel);
          }
        }}
        getRowClassName={(params) =>
          params.row.is_read === false ? "unread-row" : ""
        }
        sx={{
          height: boxHeight ? `${boxHeight}vh` : pxHeight,
          minHeight: "16rem",
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: colors.blueAccent[700],
            fontSize: "16px",
          },
          "& .MuiDataGrid-cell": {
            fontSize: "15px",
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
            height: "6px",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
            backgroundColor: colors.blueAccent[300],
            borderRadius: "0px",
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
          "& .MuiDataGrid-main": {
            background: colors.primary[400],
          },
        }}
        localeText={translations}
        slotProps={{
          filterPanel: {
            filterFormProps: {
              operatorInputProps: {
                disabled: true, // If you only want to disable the operator
                sx: { display: "none" }, // If you want to remove it completely
              },
              deleteIconProps: {
                sx: { display: "none" }, // If you want to remove it completely
              },
            },
            sx: {
              position: "absolute",
              top: "-115px",
              left: "0px",
              borderRadius: "4px",
            },
          },
          pagination: { showFirstButton: true, showLastButton: true },
        }}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: CustomToolbar,
        }}
        filterMode={ssrFilter ? "server" : "client"}
        onFilterModelChange={handleFilterModelChange}
        loading={isLoading}
        sortingMode={ssrSort ? "server" : "client"}
        sortingOrder={["desc", "asc", null]}
        onSortModelChange={handleSortModelChange}
        filterModel={currentFilterModal ?? { items: [] }}
      />
    </Box>
  );
};

export default DataTable;
