import { useQuery, useMutation, MutationFunction } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  RolesData,
  RoleData,
  RoleWithPermissionsData,
  RoleWithPermissions,
} from "../utils/app-types";
import { BASE_URL } from "./index";
import { ApiService } from "./api.service";
import { IResponse } from "../models/response";
import { RolesModel } from "../models/roles/roles.model";

// Get list of roles
export const useFetchRoles = (
  page = 1,
  itemsPerPage = 10,
  filters?: Record<string, unknown>,
  sort?: Record<string, unknown>
) => {
  const queryKey = ["roles", page, itemsPerPage, JSON.stringify(filters), sort];
  const { refetch, ...queryResult } = useQuery<
    IResponse<RolesModel[]>,
    AxiosError
  >(queryKey, async () => {
    const params = {
      page: page,
      per_page: itemsPerPage,
      sortField: sort?.field,
      sortDirection: sort?.sort,
      ...filters,
    };
    const roles = await ApiService.get(`/roles`, { params });
    return roles.data;
  });
  return { ...queryResult, refetch };
};

// Get roles without pagination
export const useFetchRolesWithoutPagination = () => {
  const queryKey = ["roles"];
  const { refetch, ...queryResult } = useQuery<
    IResponse<RolesModel[]>,
    AxiosError
  >(queryKey, () => ApiService.get(`/roles?pagination=0`));
  return { ...queryResult, refetch };
};

// Get list of basics roles
export const useFetchBasicRoles = () => {
  const queryKey = ["basic-roles"];
  const { refetch, ...queryResult } = useQuery<RolesData, AxiosError>(
    queryKey,
    async () => {
      const roles = await ApiService.get(`/roles/basic`);
      return roles;
    }
  );
  return { ...queryResult, refetch };
};

// Get list of extra roles
export const useFetchExtraRoles = () => {
  const queryKey = ["extra-roles"];
  const { refetch, ...queryResult } = useQuery<RolesData, AxiosError>(
    queryKey,
    async () => {
      const roles = await ApiService.get(`/roles/extra`);
      return roles;
    }
  );
  return { ...queryResult, refetch };
};

// Get role
export const useFetchRole = (id?: string) => {
  const queryKey = ["role", id];
  const { refetch, ...queryResult } = useQuery<
    RoleWithPermissionsData,
    AxiosError
  >(
    queryKey,
    async () => {
      const role = await ApiService.get(`${BASE_URL}/roles/${id}`);
      return role;
    },
    {
      staleTime: 60 * 1000,
      enabled: Boolean(id),
    }
  );
  return { ...queryResult, refetch };
};

// Create a new role
export const useCreateExtraRole = () => {
  const createExtraRole: MutationFunction<
    IResponse<RoleWithPermissions>,
    FormData
  > = async (postData: any) => {
    const response = await ApiService.post(`${BASE_URL}/roles`, postData);
    return response.data;
  };
  return useMutation(createExtraRole);
};

// Update an existing role
export const useUpdateRole = (id?: string) => {
  const updateRole: MutationFunction<IResponse<RoleData>, FormData> = async (
    updatedData: any
  ) => {
    const response = await ApiService.put(
      `${BASE_URL}/roles/${id}/permissions`,
      updatedData
    );
    return response.data;
  };
  return useMutation(updateRole);
};
